import Left from "./Left";
import Right from "./Right";
import Top from "./Top";

function HomePage() {
  return (
    <div id="main">
     <Top/>
     <Right />
     <Left />
    </div>
  );
}

export default HomePage;
